import cx from 'classnames';
import Link from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';

import {
  companyAddress,
  companyAddressGoogleMapsLink, companyAddressOnPhone,
  companyPhone,
  headerMenu,
  socialLinks,
} from '../../../site-config';
import PartDesignElementsContainer
, { designElementsParentContainerClass } from '../../part-design-elements-container';

import ArrowIcon from './assets/arrow.svg';
import IconFacebook from './assets/icon-facebook.svg';
import IconLinkedIn from './assets/icon-linked-in.svg';
import IconMapPin from './assets/icon-map-pin.svg';
import IconPhone from './assets/icon-phone.svg';
import IconTwitter from './assets/icon-twitter.svg';

import styles from './part-mobile-menu.module.scss';

const companyPhoneLink = companyPhone?.match(/\+?\d+/g).join('');

export default function PartMobileMenu({ id, className }) {
  return (
    <div className={cx(styles.container, className, designElementsParentContainerClass)} id={id}>
      <div className={styles.content}>
        <div className={styles.menuContainer}>
          {headerMenu.map(({ title, link }) => (
            <div key={title + link}>
              <Link
                href={link}
                className={styles.menuLink}
              >
                {title}
              </Link>
            </div>
          ))}
        </div>
        <Link className={styles.contactBtn} href="/contact-us">
          <span>Contact Us</span>
          <ArrowIcon />
        </Link>
        <div className={styles.socials}>
          <div className={styles.socialsTitle}>
            Follow Us:
          </div>
          <div className={styles.socialsList}>
            {socialLinks.map(({ slug, link }) => (
              <a
                className={styles.socialButton}
                href={link}
                target="_blank"
                rel="nofollow noreferrer"
                key={slug}
              >
                <div className={cx(styles.socialIcon, styles[`socialIcon_${slug}`])}>
                  {slug === 'linkedIn' && <IconLinkedIn />}
                  {slug === 'twitter' && <IconTwitter />}
                  {slug === 'facebook' && <IconFacebook />}
                </div>
              </a>
            ))}
          </div>
        </div>
        <div className={styles.contacts}>
          <div className={styles.contactsTitle}>
            Let’s talk
          </div>
          <div className={styles.contactsList}>
            <a
              href={`tel:${companyPhoneLink}`}
              rel="nofollow noreferrer"
              target="_blank"
              className={styles.contact}
            >
              <div className={styles.contactIconContainer}>
                <div className={cx(styles.contactIcon, styles.contactIcon_phone)}>
                  <IconPhone />
                </div>
              </div>
              <span className={styles.contactText}>
                {companyPhone}
              </span>
            </a>
            <a
              href={companyAddressGoogleMapsLink}
              className={styles.contact}
              rel="nofollow noreferrer"
              target="_blank"
            >
              <div className={styles.contactIconContainer}>
                <div className={cx(styles.contactIcon, styles.contactIcon_pin)}>
                  <IconMapPin />
                </div>
              </div>
              <span
                className={styles.contactText}
              >
                <span className="visibleOnDesktopAndTablet">
                  {companyAddress}
                </span>
                <span className="visibleOnPhone">
                  {companyAddressOnPhone}
                </span>
              </span>
            </a>
          </div>
        </div>
      </div>
      <PartDesignElementsContainer>
        <div className={styles.bubbleRed} />
        <div className={styles.bubbleBlue} />
      </PartDesignElementsContainer>
    </div>
  );
}

PartMobileMenu.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
};
