import cx from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { withQuery } from 'ufo';
import unwrapElement from 'unwrap-element';

import { headerMenu } from '../../site-config';

import ArrowIcon from './assets/arrow.svg';
import LogoIcon from './assets/logo2.svg';
import PartMobileMenu from './part-mobile-menu';

import styles from './part-header.module.scss';

const mobileMenuQueryKey = 'mobileMenuOpened';

export default function PartHeader() {
  const router = useRouter();

  let mobileMenuButtonHref;
  let mobileMenuOpened;
  if (!router.query[mobileMenuQueryKey]) {
    // Menu closed, open menu
    mobileMenuButtonHref = withQuery(router.asPath, { [mobileMenuQueryKey]: 'true' });
    mobileMenuOpened = false;
  } else {
    // Menu opened, close menu and go back
    mobileMenuButtonHref = withQuery(router.asPath, { [mobileMenuQueryKey]: undefined });
    mobileMenuOpened = true;
  }

  const links = headerMenu
    .map((route) => ({
      ...route,
      active: router.pathname === route.link,
    }));

  const destroyUnwrapReference = useRef(null);

  useEffect(() => {
    if (mobileMenuOpened) {
      destroyUnwrapReference.current = unwrapElement('#mobileMenu', {
        bypassSelectorsOrNodes: ['body'],
      });
    } else {
      destroyUnwrapReference.current?.();
    }
    return () => {
      // код отписки от ресурса
      destroyUnwrapReference.current?.();
    };
  }, [mobileMenuOpened]);

  const [menuSticky, setMenuSticky] = useState(false);

  const scrollTop = 50;

  useEffect(() => {
    const onScroll = () => {
      setMenuSticky(window.scrollY > scrollTop);
    };

    onScroll();

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop]);

  return (
    <>
      <div className={cx(styles.headerBox, { [styles.sticky]: menuSticky })}>
        <div className={styles.headerContainer}>
          <div className={styles.leftContainer}>
            <Link className={styles.logo} href="/">
              <LogoIcon />
            </Link>
          </div>
          <div className={styles.menuContainer}>
            {links.map(({ title, link, active }) => (
              <div key={title + link}>
                <Link
                  href={link}
                  className={cx(styles.menuLink, { [styles.menuLinkActive]: active })}
                >
                  {title}
                </Link>
              </div>
            ))}
          </div>
          <div className={styles.rightContainer}>
            <Link className={styles.contactBtn} href="/contact-us">
              <span>Contact Us</span>
              <ArrowIcon />
            </Link>
            <Link
              className={cx(styles.burgerButton, { [styles.mobileMenuOpened]: mobileMenuOpened })}
              data-opened={mobileMenuOpened ? '' : undefined}
              href={mobileMenuButtonHref}
              shallow
            >
              <div className={styles.burgerIcon}>
                <i />
                <i />
                <i />
              </div>
            </Link>
          </div>
        </div>
      </div>
      <PartMobileMenu id="mobileMenu" className={styles.mobileMenu} />
    </>
  );
}
