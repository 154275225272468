import cx from 'classnames';
import Link from 'next/link';
import React from 'react';

import {
  footerMenu, socialLinks, companyPhone, companyAddress, companyAddressOnPhone,
  companyAddressGoogleMapsLink,
} from '../../site-config';
import PartDesignElementsContainer
, { designElementsParentContainerClass } from '../part-design-elements-container';

import IconFacebook from './assets/icon-facebook.svg';
import IconLinkedIn from './assets/icon-linked-in.svg';
import IconMapPin from './assets/icon-map-pin.svg';
import IconPhone from './assets/icon-phone.svg';
import IconTwitter from './assets/icon-twitter.svg';

import styles from './part-footer.module.scss';

const companyPhoneLink = companyPhone?.match(/\+?\d+/g).join('');

let copyrightYearsText = '2023';
const yearNow = new Date().getFullYear();
if (yearNow > +copyrightYearsText) {
  copyrightYearsText += `-${String(yearNow)}`;
}

export default function PartFooter({ className }) {
  return (
    <div className={cx(styles.container, className, designElementsParentContainerClass)}>
      <div className={styles.content}>
        <div className={styles.topLine} />
        <div className={styles.top}>
          <div className={styles.contacts}>
            <div className={styles.contactsTitle}>
              Contact Us
            </div>
            <a
              href={`tel:${companyPhoneLink}`}
              rel="nofollow noreferrer"
              target="_blank"
              className={cx(styles.contact, styles.contact_phone)}
            >
              <div className={styles.contactIconContainer}>
                <div className={cx(styles.contactIcon, styles.contactIcon_phone)}>
                  <IconPhone />
                </div>
              </div>
              <span className={styles.contactText}>
                {companyPhone}
              </span>
            </a>
            <a
              href={companyAddressGoogleMapsLink}
              className={cx(styles.contact, styles.contact_address)}
              rel="nofollow noreferrer"
              target="_blank"
            >
              <div className={styles.contactIconContainer}>
                <div className={cx(styles.contactIcon, styles.contactIcon_pin)}>
                  <IconMapPin />
                </div>
              </div>
              <span
                className={styles.contactText}
              >
                <span className="visibleOnDesktopAndTablet">
                  {companyAddress}
                </span>
                <span className="visibleOnPhone">
                  {companyAddressOnPhone}
                </span>
              </span>
            </a>
          </div>
          <div className={styles.linksAndSocials}>
            <div className={styles.links}>
              <div className={styles.linksTitle}>
                Links
              </div>
              <div className={styles.linksList}>
                {footerMenu.map(({ title, link }, index) => (
                  <Link
                    // eslint-disable-next-line react/no-array-index-key
                    key={`footer-link-${title}-${link}-${index}`}
                    href={link || ''}
                    className={styles.link}
                  >
                    {title}
                  </Link>
                ))}
              </div>
            </div>
            <div className={styles.socials}>
              <div className={styles.socialsTitle}>
                Follow Us:
              </div>
              <div className={styles.socialsList}>
                {socialLinks.map(({ slug, link }) => (
                  <a
                    className={styles.socialButton}
                    href={link}
                    target="_blank"
                    rel="nofollow noreferrer"
                    key={slug}
                  >
                    <div className={cx(styles.socialIcon, styles[`socialIcon_${slug}`])}>
                      {slug === 'linkedIn' && <IconLinkedIn />}
                      {slug === 'twitter' && <IconTwitter />}
                      {slug === 'facebook' && <IconFacebook />}
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.copyright}>
            © Copyright
            {' '}
            {copyrightYearsText}
            {' '}
            Maple Tech Talent
          </div>
        </div>
      </div>
      <PartDesignElementsContainer>
        <div className={styles.bubble} />
        <div className={styles.bubbleMobile} />
      </PartDesignElementsContainer>
    </div>
  );
}
