const defaultSiteMenu = [
  /**
   * When you change routing here, you should change filenames in "pages" folder,
   * which also used as routing
   */
  {
    title: 'Main',
    link: '/',
  },
  {
    title: 'Our Services',
    link: '/our-services',
  },
  {
    title: 'Insights',
    link: '/insights',
  },
  {
    title: 'About Us',
    link: '/about-us',
  },
];

export const headerMenu = defaultSiteMenu;

export const footerMenu = defaultSiteMenu;

export const socialLinks = [
  { slug: 'linkedIn', link: '' },
  { slug: 'twitter', link: '' },
  { slug: 'facebook', link: '' },
];

export const companyPhone = '+1 647 894 6457';

export const companyAddress = 'Unit 638, 121, Richmond St W,\n Toronto, Ontario, M5H 2K1, Canada';

export const companyAddressOnPhone = 'Unit 638, 121, Richmond St\n W, Toronto, Ontario, M5H\n 2K1, Canada';

export const companyAddressGoogleMapsLink = 'https://goo.gl/maps/Pws9NreczVJJBuvs6';

export const formsBaseUrl = 'https://api.hsforms.com/submissions/v3/integration/submit/39848021';

export const legalConsentOptions = {
  consent: {
    consentToProcess: true, // TODO Add company name
    text: 'I agree to allow ... to store and process my personal data.',
    communications: [
      {
        value: true,
        subscriptionTypeId: 999, // TODO Add company name
        text: 'I agree to receive marketing communications from ...',
      },
    ],
  },
};
