/* eslint-disable @next/next/no-img-element */
import cx from 'classnames';
import keys from 'lodash/keys';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import { createPortal } from 'react-dom';

import styles from './part-design-helper.module.scss';

const BLINK_RUNNING = 'BLINK_RUNNING';
const BLINK_PAUSED = 'BLINK_PAUSED';
const BLINK_STOPPED = 'BLINK_STOPPED';

function PartDesignHelper({ designs = {}, renderInside, classNames } = {}) {
  const imageKeys = keys(designs);

  const [blinkState, setBlinkState] = useState(BLINK_STOPPED);

  const intervalRef = useRef();

  const { asPath } = useRouter();

  const runtimeStorageKey = `DESIGN-HELPER-IMG${asPath}`;

  const [runtime, setRuntimeState] = useState(() => {
    // This will be executed only on the initial render
    // https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
    try {
      return JSON.parse(localStorage.getItem(runtimeStorageKey)) || {};
    } catch (_error) {
      return {};
    }
  });

  const setRuntime = useCallback((newRuntime) => {
    setRuntimeState(newRuntime);
    localStorage.setItem(runtimeStorageKey, JSON.stringify(newRuntime));
  }, [runtimeStorageKey, setRuntimeState]);

  const { currImgKey, [currImgKey]: { deltaX = 0, deltaY = 0 } = {} } = runtime;
  const currImageParams = currImgKey ? designs[currImgKey] : undefined;

  const [shouldShowImg, setShouldShowImg] = useState(false);

  const [shouldHighlightBtns, setShouldHighlightBtns] = useState(false);

  const [imgDimension, setImgDimension] = useState(0);

  useEffect(() => {
    const src = currImageParams?.src || currImageParams;
    if (!src) return;
    const img = new Image();
    // eslint-disable-next-line func-names
    img.onload = function () {
      // eslint-disable-next-line react/no-this-in-sfc
      setImgDimension({ width: this.width, height: this.height });
    };
    img.src = currImageParams?.src || currImageParams;
  }, [currImageParams]);

  useEffect(() => {
    // event.metaKey - pressed Command key on Macs
    // event.ctrlKey - pressed Control key on Linux or Windows
    const keydown = (event) => {
      if (!currImgKey && (event.metaKey || event.ctrlKey) && event.shiftKey) {
        setShouldHighlightBtns(true);
      }
      if (currImgKey && currImageParams && (event.metaKey || event.ctrlKey) && event.shiftKey) {
        if (blinkState === BLINK_RUNNING) {
          setBlinkState(BLINK_PAUSED);
        } else if (blinkState === BLINK_PAUSED) {
          setBlinkState(BLINK_RUNNING);
        } else {
          setShouldShowImg(true);
        }

        if (event.key === 'ArrowUp') {
          setRuntime({ ...runtime, [currImgKey]: { ...runtime[currImgKey], deltaY: deltaY - 1 } });
        }
        if (event.key === 'ArrowRight') {
          setRuntime({ ...runtime, [currImgKey]: { ...runtime[currImgKey], deltaX: deltaX + 1 } });
        }
        if (event.key === 'ArrowDown') {
          setRuntime({ ...runtime, [currImgKey]: { ...runtime[currImgKey], deltaY: deltaY + 1 } });
        }
        if (event.key === 'ArrowLeft') {
          setRuntime({ ...runtime, [currImgKey]: { ...runtime[currImgKey], deltaX: deltaX - 1 } });
        }
      }
    };
    const keyup = (event) => {
      if ((event.metaKey || event.ctrlKey) && event.shiftKey) return;
      setShouldHighlightBtns(false);
      if (blinkState === BLINK_STOPPED) setShouldShowImg(false);
    };
    document.addEventListener('keydown', keydown);
    document.addEventListener('keyup', keyup);
    return () => {
      document.removeEventListener('keydown', keydown);
      document.removeEventListener('keyup', keyup);
    };
  }, [runtime, setRuntime, currImageParams, currImgKey, deltaX, deltaY, blinkState]);

  useEffect(() => {
    clearInterval(intervalRef.current);
    if (blinkState === BLINK_RUNNING) {
      intervalRef.current = setInterval(() => {
        setShouldShowImg((v) => !v);
      }, 500);
    }
    return () => clearInterval(intervalRef.current);
  }, [blinkState, setBlinkState]);

  const markup = (
    <>
      <Head>
        {currImageParams && (
          <style>
            {`
        html, body {
          overflow-y: auto !important;
          overflow-x: auto !important;
        }
        body {
          width: ${imgDimension?.width}px !important;
          min-height: ${imgDimension?.height}px !important;
          margin-right: auto !important;
          margin-left: auto !important;
        }
        .${styles.image} {
          transform: translate(${deltaX}px, ${deltaY}px);        
        }
      `}
          </style>
        )}
      </Head>
      <div className={cx(styles.buttonsContainer, classNames)}>
        <button
          className={cx(styles.button, { [styles.buttonActive]: runtime.semiVisible })}
          onClick={() => setRuntime({ ...runtime, semiVisible: !runtime.semiVisible })}
          type="button"
        >
          Semi Visible
        </button>
        <button
          className={cx(styles.button, { [styles.buttonActive]: blinkState !== BLINK_STOPPED })}
          onClick={() => (blinkState === BLINK_STOPPED
            ? setBlinkState(BLINK_RUNNING)
            : setBlinkState(BLINK_STOPPED))}
          type="button"
        >
          {blinkState === BLINK_PAUSED
            ? (
              <strike>Blink</strike>
            ) : (
              <span>Blink</span>
            )}

        </button>
        {imageKeys.map((imageKey) => (
          <button
            className={cx(styles.button, {
              [styles.buttonActive]: imageKey === currImgKey || shouldHighlightBtns,
            })}
            key={imageKey}
            onClick={() => (imageKey === currImgKey
              ? setRuntime({ ...runtime, currImgKey: undefined })
              : setRuntime({ ...runtime, currImgKey: imageKey }))}
            type="button"
          >
            {`${imageKey} [${runtime[imageKey]?.deltaX || 0}:${runtime[imageKey]?.deltaY || 0}]`}
          </button>
        ))}
      </div>
      {currImageParams && (
        <img
          src={currImageParams?.src || currImageParams}
          className={cx(styles.image, {
            [styles.imageShowed]: shouldShowImg,
            [styles.semiVisible]: runtime.semiVisible,
          })}
          alt=""
        />
      )}
    </>
  );

  if (renderInside) {
    return markup;
  }

  return (
    <Portal>
      {markup}
    </Portal>
  );
}

function Portal(props) {
  return createPortal(
    props.children,
    document.body,
    'partDesignHelper',
  );
}

// Make component only client side rendered
// https://nextjs.org/docs/advanced-features/dynamic-import#with-no-ssr
export default dynamic(
  () => (process.env.NODE_ENV === 'development'
    ? Promise.resolve(PartDesignHelper)
    : Promise.resolve(() => null)),
  { ssr: false },
);
