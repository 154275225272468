import React from 'react';

import styles from './part-design-elements-container.module.scss';

export default function PartDesignElementsContainer({ children }) {
  return (
    <div className={styles.container}>
      {children}
    </div>
  );
}
